import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import "./SidebarRight.css";
import MailchimpForm from "./MailchimpForm";

import newsData from "../../data/NewsData.js";

import Modal from "./Modal";

function SidebarRight({ isMobile }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [isSidebarOpen, setSidebarOpen] = useState(false); // For mobile toggle thing

  const handleReadMoreClick = (newsItem) => {
    setModalContent(newsItem);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      {isMobile ? (
        <>
          <div
            className={`sidebar-right-toggle ${isSidebarOpen ? "open" : ""}`}
            onClick={toggleSidebar}
          >
            <div className="sidebar-tab">CLICK ME !</div>
          </div>
          <div
            className={`sidebar-right mobile ${
              isSidebarOpen ? "sidebar-right-visible" : "sidebar-right-hidden"
            }`}
          >
            <div className="news-container">
              <h3 className="sidebar-right-title">LATEST NEWS</h3>
              <div className="news-bar"></div>
            </div>

            {newsData.map((newsItem, index) => (
              <div className="news-card" key={index}>
                <img src={newsItem.image} alt="Icon" />
                <div className="news-card-content">
                  <h1>{newsItem.title}</h1>
                  <p>{newsItem.description}</p>
                  <button
                    className="news-btn"
                    onClick={() => handleReadMoreClick(newsItem)}
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="sidebar-right">
          <div className="news-container">
            <h3 className="sidebar-right-title">LATEST NEWS</h3>

            <div className="news-bar"></div>
          </div>
          <MailchimpForm />
          {newsData.map((newsItem, index) => (
            <div className="news-card" key={index}>
              <img src={newsItem.image} alt="Icon" />
              <div className="news-card-content">
                <h1>{newsItem.title}</h1>
                <p>{newsItem.description}</p>
                <button
                  className="news-btn"
                  onClick={() => handleReadMoreClick(newsItem)}
                >
                  Read More
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalContent.title}
      >
        {modalContent.fullContent && (
          <ReactMarkdown>{modalContent.fullContent}</ReactMarkdown>
        )}
      </Modal>
    </>
  );
}

export default SidebarRight;
