import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeContext } from "./context/ThemeContext";
import SidebarLeft from "./components/SidebarLeft/SidebarLeft";
import MobileHeader from "./components/SidebarLeft/MobileHeader";
import SidebarRight from "./components/SidebarRight/SidebarRight";
import VideoSection from "./components/FrontPage/VideoSection";
import Intro from "./components/FrontPage/Intro";
import ThemeSwitch from "./components/ThemeSwitch/ThemeSwitch";
import Okaymon from "./components/Okaymon/Okaymon";
import Types from "./components/Types/Types";
import FAQs from "./components/FAQs/FAQs";
import Contact from "./components/Contact/Contact";

import DarkBGVideoWEBM from "./assets/DarkBG.webm";
import LightBGVideoWEBM from "./assets/LightBG.webm";

import DarkMobileBG from "./assets/DarkMobileBG.avif";
import LightMobileBG from "./assets/LightMobileBG.avif";
import RetroMobileBG from "./assets/RetroMobileBG.avif";

import "./App.css";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const { currentTheme } = useContext(ThemeContext);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <Router>
      <div className={`main-layout ${currentTheme}-mode`}>
        {/* Background Video */}
        {!isMobile && (
          <video
            key={currentTheme}
            autoPlay
            loop
            muted
            playsInline
            className="background-video"
            aria-hidden="true"
          >
            {currentTheme === "dark" && (
              <>
                <source src={DarkBGVideoWEBM} type="video/webm" />
              </>
            )}
            {currentTheme === "light" && (
              <>
                <source src={LightBGVideoWEBM} type="video/webm" />
              </>
            )}
            Your browser does not support the video tag.
          </video>
        )}

        {/* Mobile Background Image */}
        {isMobile && (
          <div
            className="mobile-background"
            style={{
              backgroundImage: `url(${
                currentTheme === "dark"
                  ? DarkMobileBG
                  : currentTheme === "light"
                  ? LightMobileBG
                  : RetroMobileBG
              })`,
            }}
          ></div>
        )}

        {isMobile ? <MobileHeader /> : <SidebarLeft />}

        <div className="container">
          <Routes>
            <Route
              path="/"
              element={
                <div className="content-wrapper">
                  <VideoSection />
                  <Intro />
                </div>
              }
              exact
            />
            <Route path="/Okaymon" element={<Okaymon />} />
            <Route path="/types" element={<Types />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <SidebarRight isMobile={isMobile} />
        <ThemeSwitch />
      </div>
    </Router>
  );
}

export default App;
